import React, { useState } from "react";
import { send } from "emailjs-com";

import Modal from "../modal/Modal";
import styles from "./home.module.css";
import Button from "../button/Button";

const ContactSection = () => {
  const [isSent, setIsSent] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
  });

  const handleChangeMessage = (e: any) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    send("service_qd26t5b", "template_5sgsh3h", toSend, "wErNeEq_WzP4O7k8F")
      .then((response) => {
        setIsSent(true);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  return (
    <section className={styles.contact} id="contact">
      <h1 className={styles.contactTitle}>Contact Me </h1>

      <form onSubmit={onSubmit} className={styles.contact__form}>
        <div className={styles.contact__formField}>
          <label htmlFor="name">Name</label>
          <input
            className={styles.contact__control}
            id="name"
            type="text"
            name="from_name"
            value={toSend.from_name}
            onChange={handleChangeMessage}
          />
        </div>
        <div className={styles.contact__formField}>
          <label htmlFor="name">email</label>
          <input
            className={styles.contact__control}
            id="email"
            type="email"
            name="reply_to"
            value={toSend.reply_to}
            onChange={handleChangeMessage}
          />
        </div>
        <div className={styles.contact__formField}>
          <label htmlFor="msg">Your Message</label>
          <textarea
            rows={5}
            className={styles.contact__control}
            id="msg"
            name="message"
            value={toSend.message}
            onChange={handleChangeMessage}
          />
        </div>
        <Button text="Send" size="large" type="submit" />
      </form>
      <Modal isOpen={isSent} onClose={() => setIsSent(false)}>
        <h1 className={styles.successMessage}>
          Your message sent Successfully!
        </h1>
      </Modal>
    </section>
  );
};

export default ContactSection;
