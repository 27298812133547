import React from "react";

import glassesImage from "../../assets/images/glassess.svg";
import SkillTag from "../skill-tag/SkillTag";

import styles from "./about.module.css";

const basicsSkills = ["HTML", "CSS", "JS", "TS", "Python"];
const advancedSkills = [
  "React",
  "NextJS",
  "ExpressJS",
  "SPFx",
  "Figma",
  "Flask",
  "React Native",
];
const SkillsSection = () => {
  return (
    <section className={styles.skills}>
      <span className={styles.skillsHeader}>
        <h1 className={styles.skillstitle}>Skills</h1>
        <img
          src={glassesImage}
          alt="glasses"
          className={styles.skillsGlasses}
        />
      </span>

      <div className={styles.skills__content}>
        <div className={styles.skillsSide}>
          <h3 className={styles.skillsSideTitle}>Basics</h3>
          <div className={styles.skillsSide__content}>
            {basicsSkills.map((skill) => (
              <SkillTag label={skill} key={skill} />
            ))}
          </div>
        </div>
        <div className={styles.skillsSide}>
          <h3 className={styles.skillsSideTitle}>Advanced</h3>
          <div className={styles.skillsSide__content}>
            {advancedSkills.map((skill) => (
              <SkillTag label={skill} key={skill} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
