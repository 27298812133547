import React from "react";

import purpleLogo from "../../assets/images/purple-logo.svg";
import myGrayPicture from "../../assets/images/my-picture-transparent-gray.png";
import art from "../../assets/images/art1.svg";

import styles from "./about.module.css";

const AboutHero = () => {
  return (
    <section className={styles.hero}>
      <div className={styles.heroContent}>
        <img
          src={purpleLogo}
          alt="logo"
          className={styles.heroLogo}
          data-aos="zoom-in-up"
          data-aos-duration="700"
        />
        <h2 className={styles.heroJob} data-aos="zoom-in-up" data-aos-duration="700" data-aos-delay="100">
          Web Developer
        </h2>
        <p className={styles.heroDescription} data-aos="zoom-in-up" data-aos-duration="700" data-aos-delay="400">
          Web Developer with 2+ years of experience specializing in building
          dynamic and scalable web applications. Proficient in Next.js,
          SharePoint Framework (SPFx), and Express.js, with strong expertise in
          Figma for seamless design-to-development collaboration. Passionate
          about delivering responsive, user-friendly solutions and continuously
          improving through learning and innovation.
        </p>
      </div>

      <div className={styles.heroImageContainer}>
        <img
          src={myGrayPicture}
          alt="my personal"
          className={styles.heroImage}
        />
      </div>
      <img src={art} alt="my art shape" className={styles.heroArt} />
    </section>
  );
};

export default AboutHero;
