import React from "react";

import purpleLogo from "../../assets/images/purple-logo.svg";
import { social } from "../home/AboutSection";

import art1 from "../../assets/images/art1.svg";
import art2 from "../../assets/images/art2.svg";

import styles from "./about.module.css";

const FollowSection = () => {
  return (
    <section className={styles.follow}>
      <h1 className={styles.followTitle}>Follow Me</h1>
      <img src={purpleLogo} alt="logo" className={styles.followLogo} />

      <span className={styles.about__social}>
        {social.map((item, index) => (
          <a href={item.link} key={index} target="_blank" rel="noreferrer">
            <img src={item.icon} alt={item.name} />
          </a>
        ))}
      </span>
      <img src={art1} alt="art1" className={styles.about__art1} />
      <img src={art2} alt="art2" className={styles.about__art2} />
    </section>
  );
};

export default FollowSection;
