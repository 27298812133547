export const works = [
  {
    title: " The Daily Grind",
    describe: "Coffee plave, a place where passion meets perfection, and every cup tells a story.",
    image: "site-6.png",
    url: "https://coffee-place-nu.vercel.app/",
  },
  {
    title: "Negative Pressure KSA",
    describe: "Positive Pressure Trading is a leading provider of clean room and isolation room solutions, specializing in mechanical work and infection control.",
    image: "site-5.png",
    url: "https://negativepressure-ksa.com",
  },
  {
    title: "Binsahl Store",
    describe: "E-commerece Store for different items.",
    image: "site-1.png",
    url: "https://binsahl.com",
  },
  {
    title: "Bakery recipe",
    describe:
      "Bakery Recipes is a community-driven platform dedicated to sharing and discovering delicious bakery recipes.",
    image: "site-2.png",
    url: "https://bakery-recipes.vercel.app/",
  },
  {
    title: "Maha Binsahl",
    describe: "Graphic Designer Portfolio.",
    image: "site-3.png",
    url: "https://www.mahabnsahl.com/",
  },
  {
    title: "Yadawi Store",
    describe:
      "E-commerece Store for hand made products for all diffrent cultures.",
    image: "site-4.png",
    url: "https://yadawystore.netlify.app/",
  },
];
