import React from "react";

import purpleLogo from "../../assets/images/purple-logo.svg";
import art3 from "../../assets/images/art-3.svg";

import styles from "./portfolio.module.css";

const PortfolioHero = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle} data-aos='fade-up'>
          <span data-aos='fade-up' data-aos-delay='100'>Where</span> development meets <span data-aos='fade-up' data-aos-delay='300'>design precision.</span>
        </h1>
        <img src={purpleLogo} alt="logo" className={styles.heroLogo} data-aos='fade-up' data-aos-delay='600'/>
      </div>
      <div className={styles.heroArtContainer}>
        <img src={art3} alt="my art shape" className={styles.heroArt}/>
      </div>
    </div>
  );
};

export default PortfolioHero;
